import React, { useEffect, useState } from "react"
import Details from "./details"
import JobCarousel from "./jobCarousel"


const JobDetails = (props) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [job, setJob] = useState(null);

  useEffect(() => {
    props.fetchJob({jobKey: props.jobKey, boardKey: props.boardKey})
  }, [props.jobKey])

  useEffect(() => {
    if(props.job.s) {
      setJob(props.job)
    }
    return () => {
      setJob(null)
    }
  }, [props.job])
  
  return (
    <>
      <Details {...props} />
      {props.similarJobs && props.similarJobs.s && props.similarJobs.payload && props.similarJobs.payload.jobs.length > 0 && (
        <JobCarousel details title="offres similaires" detailsBottom jobs={props.similarJobs} />
      )}
    </>
  )
}

export default JobDetails
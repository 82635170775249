import React from "react"
import styled from 'styled-components'


const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 0.2941rem;
  width: 100%;
`

const Li = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  color: white;
  &:last-of-type {
    margin-bottom: 0;
  }
`

const Div = styled.div`
  width: 80%;
`

const Label = styled.span`
  width: 12rem;
  min-width: 12rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 12rem;
  overflow: hidden;
  margin-right: 1rem;
  font-weight: normal;
  font-size: 0.8823rem;
  line-height: 1.11758rem;
  color: white;
`
const Progress = styled.div`
  display: flex;
  height: 0.47056rem;
  overflow: hidden;
  background-color: #EBEEF3;
  border-radius: 0.47056rem;
  width: 100%;
  margin-bottom: 0.41174rem;

  .inner {
    background-color: ${({ theme }) => theme.colors.primary}!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width .6s ease;
    border-radius: 0.47056rem;
  }
`

const Revealing = (props) => {
  const revealing = props.revealing.payload
  return (
    <Ul>
      {
        revealing.map((item, index) => (
          <Li key={`revealin-${index}`}>
            <Div>
              <Progress>
                <div
                  className="inner"
                  style={{ width: `${Math.round(item[1]*100)}%`}}
                > 
                </div>
              </Progress>
              <Label>{item[0]}</Label>
            </Div>
            <span>{Math.round(item[1]*100)}%</span>
          </Li>
          )
        )
      }
    </Ul>
  )
}

export default Revealing;
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { fetchJob } from "store/actions/jobs"
import { putProfile, getProfile, addProfile, removeProfile, showConfirmationModal, showWelcomeModal } from "store/actions/profile"
import GlobalStateProvider from "context/provider"
import { getSearchParams } from "gatsby-query-params"
import Layout from "components/layout"
import SEO from "components/seo"
import JobDetails from "components/jobDetails"
import { siteTitle, api } from "config"
import { isSSR } from 'utils'
import axios from "utils/axios"

const JobPage = ({
  data,
  job,
  fetchJob,
  documentParsing,
  profile,
  putProfile,
  getProfile,
  addProfile,
  removeProfile,
  file,
  jobs,
  similarJobs,
  recommandedJobs,
  documentRevealing,
  confirmationModal,
  showConfirmationModal,
  welcomeModal,
  showWelcomeModal,
  tagging
}) => {
  const [firstLoad, setFirstLoad] = useState(true)
  const { frontmatter } = data.index.edges[0].node
  const { useSplashScreen } = frontmatter
  const queryParams = getSearchParams()
  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: true,
  }

  useEffect(() => {
    if(job.s && firstLoad) {
      if(!isSSR) {
        setTimeout(() => {
          window.analytics && window.analytics.page();
          setFirstLoad(false)
         }, 100)
      }
    }
  }, [job])
  useEffect(() => {
    if(profile?.payload?.key && queryParams?.job_key) {
      axios
      .post("tracking", {
        action: 'view',
        role: 'candidate',
        source_key: api.SOURCE_KEY_STORING,
        job_key: queryParams.job_key,
        board_key: queryParams.board_key,
        profile_key: profile.payload.key,
        author_email: profile.payload.info.email,
      })
    }
    
  }, [queryParams])

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout details>
        <SEO
          title={job.s ? `${siteTitle} | ${job.payload.name}` : siteTitle}
        />
        {queryParams?.job_key && (
          <JobDetails
            jobKey={queryParams.job_key}
            boardKey={queryParams.board_key}
            fetchJob={fetchJob}
            job={job}
            documentParsing={documentParsing}
            profile={profile}
            putProfile={putProfile}
            getProfile={getProfile}
            addProfile={addProfile}
            removeProfile={removeProfile}
            file={file}
            jobs={jobs}
            similarJobs={similarJobs}
            recommandedJobs={recommandedJobs}
            score={queryParams.s || null}
            documentRevealing={documentRevealing}
            confirmationModal={confirmationModal}
            showConfirmationModal={showConfirmationModal}
            welcomeModal={welcomeModal}
            showWelcomeModal={showWelcomeModal}
            tagging={tagging}
          />
        )}
      </Layout>
    </GlobalStateProvider>
  )
}

JobPage.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  job: state.jobs.job,
  documentParsing: state.jobs.documentParsing,
  profile: state.profile.profile,
  confirmationModal: state.profile.confirmationModal,
  welcomeModal: state.profile.welcomeModal,
  file: state.profile.file,
  tagging: state.profile.tagging,
  jobs: state.jobs.jobs,
  similarJobs: state.jobs.similarJobs,
  recommandedJobs: state.jobs.recommandedJobs,
  documentRevealing: state.jobs.documentRevealing,
})
export default connect(mapStateToProps, { fetchJob, putProfile, getProfile, addProfile, removeProfile, showConfirmationModal, showWelcomeModal })(JobPage)

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/index/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    drop: allMdx(filter: { fileAbsolutePath: { regex: "/index/drop/" } }) {
      edges {
        node {
          frontmatter {
            title
            titlePrefix
            subtitle
            subtitlePrefix
          }
        }
      }
    }
  }
`

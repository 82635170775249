import React, {useState, useEffect} from "react"
import styled from "styled-components"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Slider from "components/slider"
import Loader from "components/loader"
import ErrorMessage from "components/errorMessage"
import MobileSlider from "components/slider/mobile"
import Item from "components/slider/mobile/item"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import {contractKey, language} from "config"
import { detectMobileAndTablet, isSSR } from "utils/"
import moment from 'moment';
import { useDarkMode } from "hooks"
import { lightTheme, darkTheme } from "styles/theme"

const StyledWrapper = styled.div`
  padding: ${props => props.details  ? '0.8823rem 0 2.58808rem 0': '0'};
  background: ${props => props.details  ? props.theme.colors.background : 'unset'};
  border: ${props => props.details  ? `1px solid ${props.theme.colors.border}`: 'unset'};
  margin-bottom: 0;
  border-top: ${props => props.details   ?  (props.detailsBottom ? 'unset' : `1px solid ${props.theme.colors.border}`): 'unset'};
  border-bottom: ${props => props.details   ?  (props.detailsTop ? 'unset' : `1px solid ${props.theme.colors.border}`): 'unset'};
  width: ${props => props.details  ? 'unset': '100vw'};
  padding-bottom: ${props => props.details   ?  (props.detailsTop ? 'unset' : '2.58808rem'): 'unset'};
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: ${props => props.details  ? 'unset': 'calc(100vw - 24.2926rem)'};
  }
  h2 {
    font-weight: 600;
    font-size: 0.82348rem;
    line-height: 1.29404rem;
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    margin-left: 0.8823rem;
    margin-bottom: 0;
    margin-top: 0;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-left: 3.5292rem;
    }
  }
`
const StyledAux = styled.div`
  width: 15.2932rem;
  min-width: 15.2932rem;
  transition: transform 300ms ease 100ms;
  .hashed {
    width: 80%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='rgb(132, 162, 183)' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.plainText};
    cursor: pointer;
    margin-left: 0.5rem;
    svg {
      margin-left: 0.5rem;
    }
  }
`
const StyledDiv = styled.div`
  display: flex;
  position: relative;
  padding: 1.1764rem 0 2.3528rem 0.8823rem;
  z-index: 3;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding: 1.1764rem 0 2.3528rem 3.2351rem;
  }
  .wrapper {
    height: 14.2932rem;
    height: 14.2932rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .empty {
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.tertiary};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 0.47056rem;
    height: 14.2932rem;
    width: -webkit-fill-available;
    margin-right: 0.8823rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-right: 3.2351rem;
    }
  }
`

const StyledItem = styled.div`
  border-radius: 0.3rem;
  position: relative;
  transition: transform 300ms ease 100ms;
  margin: 0 2px;
  width: 15.2932rem;
  min-width: 15.2932rem;
  height: 14.2932rem;
  background-size: cover;
  position: relative;
  background: ${({ theme }) => theme.colors.skBackground};
  .content {
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 0.94112rem;
    line-height: 1.35286rem;
    font-weight: 600;
    color: #FFFFFF;
    padding: 0.82348rem;
    .tag {
      width: 3.5292rem;
      font-size: 0.71rem;
      line-height: 1.1764rem;
      text-align: center;
      color: #FFFFFF;
      border-radius: 5.882rem;
      padding: 1px 0.52938rem;
      z-index: 10;
      margin-bottom: 0.2941rem;
    }
    .title {
      z-index: 10;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
`

const JobCarousel = ({ jobs, details, title, detailsBottom, detailsTop, fetchJobs, boardFilters, sorting, category }) => {

  const darkModeEnabled = useDarkMode()
  const theme = darkModeEnabled ? darkTheme : lightTheme
/*   React.useEffect(()=> {
    fetchJobs({category: category, boardFilters: boardFilters})
  }, []) */

  const [windowWidth, setWindowWidth] = useState(0)
  useEffect(() => {
    let handleWindowSizeChange
    // if (isSSR) is necessary to prevent error during the gatsby build
    if (!isSSR) {
      handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
      // set initial innerWidth when component mounts
      setWindowWidth(window.innerWidth)
    }
    // Add event listener to update windowWidth in state
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [windowWidth])


  const offers = jobs.payload.jobs
  const meta = jobs.payload.jobs.meta


  let rangeDate = "";
  if(boardFilters && boardFilters.date_range_min) {
    switch(boardFilters.date_range_min) {
      case '1m':
        rangeDate = language.DATE_RANGE_1;
        break;
      case '3m':
        rangeDate = language.DATE_RANGE_2;
        break;
        case '6m':
      rangeDate = language.DATE_RANGE_3;
        break;
      case '1y':
        rangeDate = language.DATE_RANGE_4;
        break;
    }  
  }
  const items = jobs.payload.jobs || {}
  return (
    <StyledWrapper detailsBottom={detailsBottom} detailsTop={detailsTop} details={details}>
      <h2>{title} {!details && items && items?.s  && items?.payload.meta.total >= 0 ? `(${items?.payload.meta.total})` : ''}</h2>

       {(items.s || offers?.length > 0)  && (
        <>
          {offers.length > 0 ? (
            <>
            {windowWidth !== 0 && detectMobileAndTablet(windowWidth) && (
              <MobileSlider
                shownItems={offers.length + 1}
              >
                {offers.map( (offer, index) => {
                  let score = offer.score || null
                  if (sorting === 'searching') score = null
                  const contract = offer.tags?.filter(t => t.name === contractKey)?.[0]?.value || ''
                  const location = offer.location?.text || ''
                  const company =  offer.tags?.filter(t => t.name === "organization_name")?.[0]?.value || ''
                  return (
                    <Item
                      offer={offer}
                      contract={contract}
                      index={index}
                      score={score}
                      location={location}
                      company={company}
                    />
                  )
                })}
                {!details && (meta.page < meta.maxPage) && (
                  <StyledAux
                    onClick={() => items.s && !details && (meta.page < meta.maxPage) && fetchJobs({category: category, boardFilters, page: items.payload.meta.page + 1})}
                  >
                    {items.r ? 
                      <div className="hashed"><Loader /></div>
                      :
                      <div className="hashed">
                        Voir plus  <FontAwesomeIcon className="icon-left" icon={faAngleDoubleRight} />
                      </div>
                    }
                  </StyledAux>
                )}
              </MobileSlider>
            )}
            {windowWidth !== 0 && !detectMobileAndTablet(windowWidth) && (
              <Slider
                details={details}
                fetchJobs={() => items.s && !details && (meta.page < meta.maxPage) && fetchJobs({category: category, boardFilters, page: items.payload.meta.page + 1})}
                isLoading={items.r}
              >
                {offers.map( (offer, index) => {
                  let score = offer.score || null
                  if (sorting === 'searching') score = null
                  const contract = offer.tags?.filter(t => t.name === contractKey)?.[0]?.value || ''
                  const location = offer.location?.text || ''
                  const company =  offer.tags?.filter(t => t.name === "company")?.[0]?.value || ''
                  return (
                    <Slider.Item
                      offer={offer}
                      contract={contract}
                      index={index}
                      score={score}
                      location={location}
                      company={company}
                    />
                  )
                })}
              </Slider>
            )}
            </>
          )

          :
           null
          }
        </>
      )}
      {(items.s && offers.length === 0) && (
        <StyledDiv>
          <div className="empty">
            <ErrorMessage 
              title={language.empty_message_title}
              subtitle={language.empty_message_subtitle}
              type="empty"
              min
            />
          </div>
        </StyledDiv>
      )}
      {((items.r && !offers?.length) || Object.keys(items).length === 0) && (
        <StyledDiv>
          {[1,2,3,4,5].map(item => (
            <StyledItem>
              <div className="content">
                <div className="tag">
                  <SkeletonTheme color={theme.colors.skColor} highlightColor={theme.colors.skHighlightColor}>
                    <p>
                      <Skeleton count={1} />
                    </p>
                  </SkeletonTheme>
                </div>
                <span className="title">
                <SkeletonTheme color={theme.colors.skColor} highlightColor={theme.colors.skHighlightColor}>
                  <p>
                    <Skeleton count={2} />
                  </p>
                </SkeletonTheme>
                </span>
              </div>
            </StyledItem>
          ))}
        </StyledDiv>
      )}

      {items.f && (
        <StyledDiv>
          <div className="empty">
            <ErrorMessage 
              title={language.error_message_title}
              subtitle={language.error_message_try_later}
              type="error"
              min
            />
          </div>
        </StyledDiv>
      )}
    </StyledWrapper>
  )
}


export default JobCarousel
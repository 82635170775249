import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Taggy from "./taggy"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import ErrorMessage from "components/errorMessage"
import {language} from "config"
import { useDarkMode } from "hooks"
import { lightTheme, darkTheme } from "styles/theme"

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.colors.tertiary};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 0.47056rem;
  padding: 1.5rem 2rem;
  padding-right: 1rem;
  margin-top: 2rem;
`

const StyledScroll = styled.div`
  color: ${({ theme }) => theme.colors.plainText};
  min-height: 30.528rem;
  padding-right: 1rem;
  &::-webkit-scrollbar {
    width: 0.35292rem;
    background: #091420;
    border-radius: 5.882rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #091420;
    border-radius: 5.882rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 5.882rem;
  }
  .title {
    font-weight: 600;
    font-size: 1.1764rem;
    line-height: 190%;
  }
  .content {
    font-weight: normal;
    font-size: 1rem;
    line-height: 190%;
    margin: 1rem 0;
    white-space: pre-wrap;
  }
  .content.first {
    margin-bottom: 3rem;
  }
`

const Parsing = (props) => {
  const darkModeEnabled = useDarkMode()
  const theme = darkModeEnabled ? darkTheme : lightTheme
  let taggyDesciption = ""
  let taggyProfile = ""
  if(props.documentParsing.s) {
    const text = props.documentParsing.payload.text
    let textDescription = text.split('/n/n')[0]
    let textProfile = text.split('/n/n')[1]
    const documentParsing = props.documentParsing.payload.entities.filter(item => item.label !== 'JobTitle')
    const spans = documentParsing.map(ent => {
        switch (ent.label) {
            case "first_name": 
                return { type: "prénom", start: ent.start, end: ent.end }
            case "last_name": 
                return { type: "nom", start: ent.start, end: ent.end }
            case "date": 
                return { type: "date", start: ent.start, end: ent.end }
            case "duration": 
                return { type: "durée", start: ent.start, end: ent.end }
            case "location": 
                return { type: "localisation", start: ent.start, end: ent.end }
            case "company": 
                return { type: "entreprise", start: ent.start, end: ent.end }
            case "job_title": 
                return { type: "poste", start: ent.start, end: ent.end }
            case "task": 
                return { type: "tâche", start: ent.start, end: ent.end }
            case "school": 
                return { type: "école", start: ent.start, end: ent.end }
            case "education_title": 
                return { type: "formation", start: ent.start, end: ent.end }
            case "course": 
                return { type: "cours", start: ent.start, end: ent.end }
            case "skill_hard": 
                return { type: "savoir-faire", start: ent.start, end: ent.end }
            case "skill_soft": 
                return { type: "savoir-être", start: ent.start, end: ent.end }
            case "certification": 
                return { type: "certification", start: ent.start, end: ent.end }
            case "language": 
                return { type: "langue", start: ent.start, end: ent.end }
            case "interest": 
                return { type: "interêt", start: ent.start, end: ent.end }
            case "email": 
                return { type: "email", start: ent.start, end: ent.end }
            case "phone": 
                return { type: "téléphone", start: ent.start, end: ent.end }
            case "url": 
                return { type: "site-web", start: ent.start, end: ent.end }
            default: 
                return { type: ent.label.toLowerCase(), start: ent.start, end: ent.end }

        }   
    })


    let spansDescription = spans.filter(span => span.end <= textDescription.length);
    let spansProfile = spans.filter(span => span.start > textDescription.length);
    spansProfile = spansProfile.map(span => ({type: span.type, start: span.start - textDescription.length -4, end: span.end - textDescription.length - 4 }))
    const ents = [
      { type: 'prénom', color: { r: 241, g: 137, b: 14, a: 1 } },
      { type: 'nom', color: { r: 190, g: 117, b: 32, a: 1 } },
      { type: 'date', color: { r: 32, g: 73, b: 248, a: 1 } },
      { type: 'durée', color: { r: 120, g: 190, b: 67, a: 1 } },
      { type: 'localisation', color: { r: 233, g: 188, b: 66, a: 1 } },
      { type: 'entreprise', color: { r: 100, g: 133, b: 168, a: 1 } },
      { type: 'poste', color: { r: 56, g: 128, b: 238, a: 1 } },
      { type: 'tâche', color: { r: 29, g: 175, b: 182, a: 1 } },
      { type: 'école', color: { r: 211, g: 84, b: 56, a: 1 } },
      { type: 'formation', color: { r: 195, g: 48, b: 220, a: 1 } },
      { type: 'cours', color: { r: 80, g: 59, b: 96, a: 1 } },
      { type: 'savoir-faire', color: { r: 153, g: 102, b: 255, a: 1 } },
      { type: 'savoir-être', color: { r: 60, g: 210, b: 57, a: 1 } },
      { type: 'certification', color: { r: 178, g: 215, b: 50, a: 1 } },
      { type: 'langue', color: { r: 242, g: 94, b: 116, a: 1 } },
      { type: 'interêt', color: { r: 236, g: 203, b: 15, a: 1 } },
      { type: 'email', color: { r: 236, g: 61, b: 23, a: 1 } },
      { type: 'téléphone', color:  { r: 47, g: 130, b: 134, a: 1 } },
      { type: 'site-web', color: { r: 194, g: 20, b: 96, a: 1 } }
    ]

    taggyDesciption = (<Taggy text={textDescription} spans={spansDescription} ents={ents} />)
    taggyProfile = (<Taggy text={textProfile} spans={spansProfile} ents={ents} />)
  }
  return (
    <StyledWrapper>
      <StyledScroll>
        {props.documentParsing.s && (
          <>
            <div className="title">{language.post_description}</div>
            <div className="content first">{taggyDesciption}</div>
            {/* <div className="title">Profil recherché</div>
            <div className="content">{taggyProfile}</div> */}
          </>
        )}
        {props.documentParsing.r && (
          <>
            <div className="title">
              <SkeletonTheme color={theme.colors.skColor} highlightColor={theme.colors.skHighlightColor}>
                <p>
                  <Skeleton count={1} />
                </p>
              </SkeletonTheme>
            </div>
            <div className="content first">
              <SkeletonTheme color={theme.colors.skColor} highlightColor={theme.colors.skHighlightColor}>
                <p>
                  <Skeleton count={9} />
                </p>
              </SkeletonTheme>
            </div>
          </>
        )}
        {props.documentParsing.f && (
          <ErrorMessage 
            title={language.error_message_title}
            subtitle={language.error_message_retry}
            type="error"
          />
        )}
      </StyledScroll>
    </StyledWrapper>
  )
}

export default Parsing
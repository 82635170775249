import React from 'react'
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import * as Icons from '@fortawesome/free-solid-svg-icons';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import {tags, offerRedirect, language} from "config"

import moment from 'moment'
import 'moment/locale/fr' 
import Parsing from './parsing'
import Revealing from "./revealing"
import MinDropzone from "components/minDropzone"
import ProfileCard from "components/searchBox/profileCard"
import Loader from 'components/loader'
import ErrorMessage from "components/errorMessage"
import Score from "components/score"
import { useDarkMode } from "hooks"
import { lightTheme, darkTheme } from "styles/theme"
import ConfirmationForm from "components/form"


const iconList = Object
  .keys(Icons)
  .filter(key => key !== "fas" && key !== "prefix" )
  .map(icon => Icons[icon])

library.add(...iconList)

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem;
  max-width: 82.348rem;
  min-height: 50rem;
  width: 100%;
  margin: auto;
  position: relative;
  .offer {
    //grid-row: 2;
  }
  .info {
    padding: 2.5rem 0;
    padding-top: 2.7rem;
  }
  .ref-container {
    display: flex;
    .ref {
      font-weight: 600;
      font-size: 0.82348rem;
      line-height: 160%;
      color: ${({ theme }) => theme.colors.text};
      max-width: 25rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      .icon-left {
        margin-right: 0.3rem;
      }
    }
    span {
      margin-left: 0.5rem;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  // .name-container {
  //   display: flex;
  //   justify-content: flex-start;
  //   align-items: center;

  // }
  h1 {
    font-weight: normal;
    font-size: 1.41168rem;
    line-height: 1.94106rem;
    color: ${({ theme }) => theme.colors.plainText};
    margin-bottom: 0;
    margin-top: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: grid;
    grid-gap: 2.3528rem;
    grid-template-columns: auto 23.528rem;
    padding: 1rem 6rem;
    .offer {
      grid-row: 1;
      padding: 2.5rem 0;
    }
    .info {
      position: sticky;
      top: 2rem;
      z-index: 1;
      height: fit-content;
    }
  }
`

const StyledControl = styled.div`
  padding-top: 1.7646rem;
  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    border-bottom: none;
  }

  .label {
    font-weight: 600;
    font-size: 0.82348rem;
    line-height: 0.94112rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.text};
    margin-bottom: 0.64702rem;
    text-transform: uppercase;
  }
`
const StyledCard = styled.div`
  background: ${({ theme }) => theme.colors.tertiary};
  border: 1px solid ${({ theme }) => theme.colors.border};
  padding: 1rem 1.23522rem;
  border-radius: 0.47056rem;
  .tag {
    margin-bottom: 0.64702rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    color: ${({ theme }) => theme.colors.plainText};
    font-weight: normal;
    font-size: 0.82348rem;
    line-height: 1rem;
    max-width: 19.17556rem;
    display: flex;
    flex-wrap: nowrap;
    .icon-fixed-width {
      width: 1.6rem;
      color: ${({ theme }) => theme.colors.primary};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: 1.6rem;
      max-width: 1.6rem;
    }
    .tag-text {
      overflow: hidden;
      display: flex;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`
const StyledButton = styled.a`
  background: rgba(21, 37, 52, 0.56);
  border: none;
  box-sizing: border-box;
  border-radius: 0.5882rem;
  color: white;
  font-size: 0.94112rem;
  line-height: 1.1764rem;
  text-align: center;
  padding: 1.1764rem;
  cursor: pointer;
  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
  }
  margin: 0 auto;
  width: 100%;
  margin-top: 1rem;
`

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40rem;
`

const copyLink = (e, url) => {
  e.stopPropagation();
  const dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.setAttribute('value', url);
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
}

const Details = (props) => {

  const darkModeEnabled = useDarkMode()
  const theme = darkModeEnabled ? darkTheme : lightTheme

  const confirmWelcome = (value) => {
    if(value) {
      props.putProfile({profile: props.profile.payload, action: 'init'})
    } else {
      props.showWelcomeModal(false)
      props.showConfirmationModal(false)

    }
  }


  if (props.job.f) {
    return (
      <ErrorMessage 
        title={language.error_message_title}
        subtitle={language.error_message_retry}
        type="error"
      />
    )
  } else if (props.job.s) {
    const job = props.job.payload
    const name = job.name
    const reference = job.reference
    const location = job.location?.text || ''
    const creationDate =  moment(job.created_at).format('DD MMM YYYY')
    const profileJobTag = { name: 'application_boardKey_jobKey', value: `${job.board.key}_${job.key}` }
    const profile = props.profile?.payload?.payload || props.profile?.payload
    return (
      <StyledWrapper>
        <div className="offer">
          <div className="ref-container">
            <div className="ref">
              <FontAwesomeIcon className="icon-left" icon="hashtag" />{reference}
            </div>
            <span
            role="button"
            onClick={e => copyLink(e, reference)}
            >
              {language.copy} 
            </span>
          </div>
          <h1>{name}</h1>
          {props.score && (
            <Score
              relative
              score={props.score}
            />
          )}
          <Parsing documentParsing={props.documentParsing} />
        </div>
        <div className="info">
          <StyledControl>
            <div className="label">
            {props.file && props.file.fileName ? `Bienvenue ${profile?.info?.first_name && profile?.info?.first_name || ''} ` : language.dropzone_text} 
            </div>
            {props.file && props.file.fileName ? (
              <ProfileCard
                profile={profile}
                profileState={props.profile}
                removeProfile={props.removeProfile}
                details
                putProfile={props.putProfile}
                getProfile={props.getProfile}
                jobKey={job.key}
                jobUrl={job.url}
                jobReference={reference}
                boardKey={job.board.key}
                isLoading={props.profile.r}
                profileJobTag={profileJobTag}
                loaded={props.profile.s}
                confirmationModal={props.confirmationModal}
                showConfirmationModal={props.showConfirmationModal}
                welcomeModal={props.welcomeModal}
                showWelcomeModal={props.showWelcomeModal}
                tagging={props.tagging}
              />
              )
              :
              (
                <MinDropzone
                  addProfile={props.addProfile}
                  profile={props.profile}
                  details
                  boardKey={job.board.key}
                  jobKey={job.key}
                />
              )
            }
          </StyledControl>
          <StyledControl>
        {/*     <div className="label">
              {content.offer.positionTitle}
            </div> */}
            <StyledCard>
              {location && (
                <div className="tag">
                  <div className="icon-fixed-width"><FontAwesomeIcon className="icon-left" icon="map-marker-alt" /></div><span className="tag-text">{location}</span>
                </div>
              )}
              {tags.map(tag => {
                if(job.tags?.filter(t => t.name === tag.key)?.[0]?.value) {
                  return (
                    <div className="tag">
                      <div className="icon-fixed-width"><i className={`icon-left fa ${tag.icon}`} /></div>
                      <span className="tag-text">
                        {tag.label ? <>{tag.label}:&nbsp;&nbsp;&nbsp;</> : null}
                        {tag.renderer(job.tags?.filter(t => t.name === tag.key)?.[0]?.value) || ''}
                      </span>
                    </div>
                  )
                } else {
                  return null
                }   
              })}
              {creationDate && (
                <div className="tag">
                  <div className="icon-fixed-width"><FontAwesomeIcon className="icon-left" icon="calendar" /></div>
                  <span className="tag-text">
                    Publié le :&nbsp;&nbsp;{creationDate}
                  </span>
                </div>
              )}
            </StyledCard>
          </StyledControl>
        </div>
      </StyledWrapper>
    );
  } else {
    return (
      <StyledWrapper>
        <div className="offer">
          <div className="ref">
            <SkeletonTheme color={theme.colors.skColor} highlightColor={theme.colors.skHighlightColor}>
                <Skeleton count={1} />
            </SkeletonTheme>
          </div>
          <h1>
            <SkeletonTheme color={theme.colors.skColor} highlightColor={theme.colors.skHighlightColor}>
                <Skeleton count={1} />
            </SkeletonTheme>
          </h1>
          <Parsing documentParsing={{r: true}} />
        </div>
        <div className="info">
          <StyledControl>
              <div className="label">
                <SkeletonTheme color={theme.colors.skColor} highlightColor={theme.colors.skHighlightColor}>
                  <p>
                    <Skeleton count={1} />
                  </p>
                </SkeletonTheme>
              </div>
              <StyledCard>
                <SkeletonTheme color={theme.colors.skColor} highlightColor={theme.colors.skHighlightColor}>
                  <p>
                    <Skeleton count={2} />
                  </p>
                </SkeletonTheme>
              </StyledCard>
          </StyledControl>
          <StyledControl>
              <div className="label">
                <SkeletonTheme color={theme.colors.skColor} highlightColor={theme.colors.skHighlightColor}>
                  <p>
                    <Skeleton count={1} />
                  </p>
                </SkeletonTheme>
              </div>
              <StyledCard>
                <SkeletonTheme color={theme.colors.skColor} highlightColor={theme.colors.skHighlightColor}>
                  <p>
                    <Skeleton count={6} />
                  </p>
                </SkeletonTheme>
              </StyledCard>
          </StyledControl>
        </div>
      </StyledWrapper>
    )
  }
}

export default Details